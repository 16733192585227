<template>
  <div>
    <p v-show="errorTableData" class="text-center">
      <v-icon>mdi-alert-circle</v-icon>
      Error obteniendo los datos
    </p>

    <v-card v-if="!errorTableData">
      <v-card-title>
        <NuevoServicio></NuevoServicio>

        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Buscar"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>

      <v-data-table
        :headers="tableHeaders"
        :items="tableData"
        :search="search"
        :loadingTableData="loadingTableData"
        loadingTableData-text="Cargando..."
        @click:row="rowClick"
      >
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import axios from 'axios';
import NuevoServicio from '../components/Servicios/NuevoServicio';

export default {
  data() {
    return {
      tableData: [],
      errorTableData: false,
      loadingTableData: true,
      search: '',
      tableHeaders: [
        { text: 'Nombre', value: 'nombre' },
        { text: 'Profesor', value: 'profesor' },
        { text: 'Observaciones', value: 'observaciones' },
      ],
    };
  },
  methods: {
    async getTableData() {
      try {
        this.loadingTableData = true;

        const res = await axios.get(process.env.VUE_APP_API_URL + '/servicios');

        this.tableData = res.data;

        this.loadingTableData = false;
      } catch (error) {
        this.loadingTableData = false;

        this.errorTableData = true;
      }
    },
    async rowClick(row) {
      this.$router.push({ name: 'Servicio', params: { id: row._id } });
    },
  },
  components: {
    NuevoServicio,
  },
  mounted() {
    this.getTableData();
    this.$emit('usePanel', true);
  },
};
</script>
