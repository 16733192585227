<template>
  <v-dialog persistent v-model="toggleDialog" max-width="500px">
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        class="mr-4"
        dark
        color="blue"
        v-bind="attrs"
        v-on="on"
        @click="getProfesores"
      >
        <v-icon dark>
          mdi-plus
        </v-icon>
        Nuevo
      </v-btn>
    </template>

    <v-card :loading="loading">
      <v-card-title>
        Nuevo servicio
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Nombre"
          v-model="nuevoServicio.nombre"
        ></v-text-field>

        <v-autocomplete
          v-model="nuevoServicio.profesor"
          :items="profesores"
          label="Profesor"
        ></v-autocomplete>

        <v-text-field
          label="Observaciones"
          v-model="nuevoServicio.observaciones"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="blue"
          text
          @click="
            toggleDialog = false;
            $emit('edit');
          "
        >
          Cancelar
        </v-btn>
        <v-btn color="blue" text @click="crear()">
          Guardar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import axios from 'axios';

export default {
  name: 'NuevoServicio',
  data() {
    return {
      loading: false,
      toggleDialog: false,
      profesores: [],
      nuevoServicio: {
        nombre: '',
        profesor: '',
        observaciones: '',
      },
    };
  },
  methods: {
    async crear() {
      try {
        this.loading = true;

        const res = await axios.post(
          process.env.VUE_APP_API_URL + '/servicios/nuevo',
          this.nuevoServicio
        );

        this.loading = false;

        this.toggleDialog = false;

        this.$router.push({
          name: 'Servicio',
          params: { id: res.data._id },
        });
      } catch (error) {
        this.loading = false;

        alert(error.response.data);
      }
    },
    async getProfesores() {
      try {
        let profesores = await axios.get(
          process.env.VUE_APP_API_URL + '/profesores'
        );
        profesores = profesores.data;

        for (let i = 0; i < profesores.length; i++) {
          this.profesores.push(
            `${profesores[i].nombre} ${profesores[i].primerApellido} ${profesores[i].segundoApellido}`
          );
        }
      } catch (error) {
        alert('Error - No se pueden obtener los profesores');

        this.toggleDialog = false;
      }
    },
  },
};
</script>
